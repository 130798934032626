<template>
  <div>

    <div class="d-flex justify-content-center h-100 content">
      <b-row class="w-100" style="max-width: 1200px;">

        <b-col md="12">
          <h1 class="mt-4" style="font-size: 25px;">{{ 'frequently asked questions' | capitalizeAll }}</h1>
          <h2 style="font-size: 20px;">Questions about the program? We have the answers.</h2>
        </b-col>

        <b-col md="8" class="mt-4-lg">
          
          <div class="mt-4" id="faq-container">
            <div v-for="(item,index) in faq" :key="index">
              <a href="#" @click.prevent="toggleFaq(index)" class="no-select d-flex" style="padding-left: 40px;">
                <b-icon-chevronDown v-show="item.active" style="position: absolute; width: 25px; height: 25px !important; margin-right: 15px; margin-left: -30px;"></b-icon-chevronDown>
                <b-icon-chevronRight v-show="!item.active" style="position: absolute; width: 25px; height: 25px !important; margin-right: 15px; margin-left: -30px;"></b-icon-chevronRight>
                {{ item.question }}</a>
              <p v-show="item.active" class="pre-line" style="margin-left: 42px; margin-top: 10px; font-weight: 600;">{{ item.answer }}</p>
              <hr v-if="index != faqLength">
            </div>
          </div>

        </b-col>

        <b-col md="4">
          <b-img
            fluid
            src="@/assets/faq_2.jpg"
            style="margin-top:25px; padding: 10px;"
            class="photo"
          />
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>

export default {
  name: 'faq',
  components: {
    
  },

  metaInfo: {
    title: 'Frequently Asked Questions'
  },

  data() {
    return {

      // 'active' will be true if the question is expanded
      faq: {
        1: {
          'question': 'What prompted the development of Honda Digital Solutions?',
          'answer':   'The Honda Team has worked with the Digital Dealer Council to design this digital program for all Honda dealers. The program strongly positions you to support the developing digital expectations of your customers.',
          'active':   false,
        },
        2: {
          'question': 'How do vendors get selected to participate in the program?',
          'answer':   'A rigorous selection process is followed to ensure Honda dealers receive the best website and digital products and services.',
          'active':   false,
        },
        3: {
          'question': 'What are the primary benefits of enrolling in Honda Digital Solutions?',
          'answer':   'The dealer digital program is unique to the industry because it has been designed to meet the specific needs of Honda dealers. The challenges Honda dealers face in managing multiple vendor contracts, monthly meetings and reporting dashboards result in inefficiencies, lost time and ultimately, lost revenue.\n\nWell-respected vendor partners have been selected to offer a core set of digital solutions including website, digital advertising, and SEO. These core technologies are critical to the success of the seamless customer journey. Our approach packages these solutions from the vendor of your choice, resulting in management simplicity, more value, and a holistic integrated digital marketing strategy and for your dealership.',
          'active':   false,
        },
        4: {
          'question': 'Is Honda mandating the program? Do I have to enroll in the program?',
          'answer':   'It is not mandated to join the program, however, SEM efficiencies in industry programs have shown as much as 20% savings in enterprise digital advertising spend. In addition, Honda has carefully selected vendors and services to support dealers and their digital goals that packages that provide the services customers expect online. There are many benefits to being on program including:\n\n- VIP support for program related questions like enrollment and billing\n- Digital Field Consultants in every Honda Zone office with retail sales experience\n- Business Listing Syndication to ensure dealer information is current and correct across hundreds of dealer listing sites\n- Standardized and self-service reporting tools in a single dashboard\n- Universal call-tracking and reputation management scoring to provide better insight into ROI and allow dealers to properly benchmark performance',
          'active':   false,
        },
        5: {
          'question': 'Is Honda instituting any new policies as part of the new program?',
          'answer':   'Honda is not mandating participation in the program. Honda dealers will continue to have choice with who they choose to work with for their digital needs. Dealers who do not participate will not have access to any benchmarking data nor receive the significant cost savings of the services seen with the Platform Services, including Consulting, Call Tracking from the automobiles.honda.com site, and many other benefits.',
          'active':   false,
        },
        6: {
          'question': 'How will I be billed for the monthly program fee?',
          'answer':   'You will be billed by JD Power who is the program operator. The Enterprise Services fee is in addition to the vendor fees and covers the HDS Enterprise Services, including Dealer Consulting, Baseline Reputation Management, Dashboard & Analytics, Business Listing Syndication and Call Tracking & Training.',
          'active':   false,
        },
        7: {
          'question': 'How long is the contract with each provider on program?',
          'answer':   'All contracts with providers will be month-to-month to provide the most flexibility for dealers.',
          'active':   false,
        },
        8: {
          'question': 'Are my leads captured sent to Honda?',
          'answer':   'Sales and phone leads are sent via Honda to your CRM. Any leads not transferred to Honda are sent directly to your CRM.',
          'active':   false,
        },
      },
    };
  },

  methods: {
    toggleFaq(index) {
      var current = this.faq[index].active;
      this.$set(this.faq[index], 'active', !current);
    },
  },

  computed: {
    faqLength() {
      return Object.keys(this.faq).length;
    },

    indexVideo() {
      return this.$refs.indexVideo;
    },
  },
}



</script>

<style>

#faq-container {
  max-width: 600px;
}

#faq-container a {
  color: #2d2d2d;
  max-width: 600px;
}

#faq-container a:hover {
  text-decoration: none;
  cursor: pointer;
  color: #5c88da;
}

#faq-container i:hover {
  color: rgb(23, 92, 142);
}

.faq-expanded {
  padding-top: 10px;
  padding-right: 23px;
  padding-bottom: 23px;
  padding-left: 22px;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
  supported by Chrome and Opera */
}

.content {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {

  .content {
    margin-top: 0px;
    padding-top: 60px;
  }

}

</style>